import { Container, makeStyles, Typography } from "@material-ui/core";
import Carousel from "./Carousel";

const useStyles = makeStyles((theme) => ({
  banner: {
    backgroundColor: "#030B15",
  },
  
  bannerContent: {
    height: 400,
    display: "flex",
    flexDirection: "column",
    paddingTop: 25,
    justifyContent: "space-around",
  },
  tagline: {
    display: "flex",
    height: "40%",
    flexDirection: "column",
    justifyContent: "center",
    textAlign: "center",
  },
  carousel: {
    height: "50%",
    display: "flex",
    alignItems: "center",
  },
}));

function Banner() {
  const classes = useStyles();

  return (
    <div className={classes.banner}>
      <Container className={classes.bannerContent}>
        <div className={classes.tagline}>
          <Typography
            variant="h2"
            style={{
              fontWeight: "bold",
              marginBottom: 15,
              fontFamily: "Montserrat",
            }}
          >
            <Typography
  variant="h4"
  style={{ margin: 18, fontFamily: "Montserrat", fontWeight: "bold" }}
>
  <span style={{ color: "white" }}>Expand Your Token's Reach</span>
  <span style={{ color: "#006400" }}></span>
</Typography>

          </Typography>
          <Typography variant="subtitle2" style={{
    color: "darkgrey",
    textTransform: "capitalize",
    fontFamily: "Montserrat",
  }}
>
    List your unique token on LlamaCoinDex and reach a passionate crypto audience to boost your visibility and growth opportunities. Contact us at 
    <a href="mailto:llamahold@gmail.com" target="_blank" rel="noreferrer noopener"><strong> Here</strong></a>.
</Typography>


        </div>
        <Carousel/>
      </Container>
      
    </div>
  );
}

export default Banner;
